<!--
 * @Author: your name
 * @Date: 2021-07-24 11:20:37
 * @LastEditTime: 2021-09-25 15:34:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\auth.vue
-->
<template>
  <div class="auth" :style="{ height: $store.state.clientHeight + 'px' }">
    <img class="logo" src="@/assets/img/logo.png" />
    <div class="vessel">
      <div class="cert">
        <div class="last" @click="last">返回</div>
        <div class="title">实名认证</div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="150px"
          class="demo-ruleForm"
          style="width: 650px; padding-bottom: 50px"
          size="small"
        >
          <el-form-item label="真实姓名" prop="userName">
            <el-input
              v-model="ruleForm.userName"
              maxlength="10"
              show-word-limit
              clearable
              placeholder="真实姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="证件类型" prop="idType">
            <el-select v-model="ruleForm.idType" placeholder="请选择证件类型">
              <el-option label="居民身份证" :value="1"></el-option>
              <el-option label="香港居民身份证" :value="2"></el-option>
              <el-option label="澳门居民身份证" :value="3"></el-option>
              <el-option label="台湾身份证" :value="4"></el-option>
              <el-option label="护照" :value="5"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="is-required" label="证件号码" prop="idNumber">
            <el-input
              v-model="ruleForm.idNumber"
              maxlength="20"
              show-word-limit
              clearable
              placeholder="证件号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="上传照片" prop="visualHeadImg">
            <el-upload
              class="avatar-uploader"
              :action="`${$baseURL}/file/privateUpload`"
              :headers="headers"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="ruleForm.visualHeadImg"
                :src="ruleForm.visualHeadImg"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div slot="tip" class="el-upload__tip">
                上传本人正面照。图片必须清晰符合打印条件。照片会应用于志愿者证书证件照、服务证明证件照。建议尺寸：250*350
              </div>
            </el-upload>
          </el-form-item>
          <p class="annotation">
            注：根据协会要求，完成实名认证方可成为注册志愿者，同时进入个人的管理中心。
          </p>
          <div class="button" @click="submitForm('ruleForm')">实名认证</div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { realName, getUser } from "@/api";
import { getToken, getUserInfo, setUserInfo } from "@/utils/auth";
import IdentityCodeValid from "@/utils/checkIdent";
export default {
  data() {
    var checkIdNumber = (rule, value, callback) => {
      if (this.ruleForm.idType == 1) {
        var res = IdentityCodeValid(value);
        if (res) {
          callback();
        } else {
          callback(new Error("身份证号格式错误"));
        }
      } else {
        if (value === "") {
          callback(new Error("请输入证件号码"));
        } else {
          callback();
        }
      }
    };
    return {
      headers: {
        token: getToken(),
      },

      headImg: "",
      ruleForm: {
        userName: "",
        idType: "",
        idNumber: "",
        visualHeadImg: "",
      },

      rules: {
        userName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        idType: [
          { required: true, message: "请选择证件类型", trigger: "change" },
        ],
        idNumber: [{ validator: checkIdNumber, trigger: "blur" }],
        visualHeadImg: [
          { required: true, message: "请上传证件照", trigger: "blur" },
        ],
      },
    };
  },

  methods: {
    last() {
      let userInfo=getUserInfo()
      if (userInfo.userType === 0) {
        this.$router.push({ name: "login" });
      } else {
        this.$router.go(-1); //返回上一层
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {};
          data.userName = this.ruleForm.userName;
          data.idType = this.ruleForm.idType;
          data.idNumber = this.ruleForm.idNumber;
          data.headImg = this.headImg;
          console.log(data);
          realName(data).then((res) => {
            const hasUserInfo = getUserInfo();
            hasUserInfo.userType = 1;
            setUserInfo(hasUserInfo);
            this.$store.commit("login", hasUserInfo);
            this.$router.push({ name: "auth" });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.ruleForm.visualHeadImg = res.data.visualUrl;
      this.headImg = res.data.privateUrl;
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 < 500;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG/PNG 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 500K!");
        return false;
      }
      return true;
    },
  },
  mounted() {
    getUser().then((res) => {
      if (res.data.idNumber) {
        this.ruleForm.userName = res.data.userName;
        this.ruleForm.idType = res.data.idType;
        this.ruleForm.idNumber = res.data.idNumber;
        this.ruleForm.visualHeadImg = res.data.visualHeadImg;
        this.headImg = res.data.privateHeadImg;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.auth {
  background: url("../../assets/img/authBG.png") right bottom;
  position: relative;
  .logo {
    width: 232px;
    position: absolute;
    top: 47px;
    left: 15%;
  }
  .vessel {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .cert {
      width: 766px;
      height: 600px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
      border-radius: 20px;
      box-sizing: border-box;
      padding-top: 40px;
      position: relative;
      .last {
        cursor: pointer;
        font-size: 22px;
        font-family: MicrosoftYaHei;
        color: #ff6e6e;
        position: absolute;
        right: 35px;
      }
      .title {
        font-size: 24px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #ff6e6e;
        text-align: center;
        margin-bottom: 40px;
      }
      .annotation {
        font-size: 12px;
        color: #ff6e6e;
        margin-left: 120px;
      }
      .button {
        width: 140px;
        height: 40px;
        background: #ff6e6e;
        border-radius: 24px;
        cursor: pointer;
        text-align: center;
        font-size: 16px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 40px;
        margin-top: 40px;
        margin-left: 320px;
      }
      .el-scrollbar__wrap {
        height: 700px;
      }
    }
  }
}
</style>